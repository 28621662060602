import React from 'react';
import { useState, useCallback, useEffect } from 'react'
import Confetti from 'react-confetti'
import picture from '../img/Picture.jpg'


export default function Component() {
  const [windowDimension, setWindowDimension] = useState({ width: 0, height: 0 })

  const detectSize = useCallback(() => {
    setWindowDimension({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }, [])

  useEffect(() => {
    window.addEventListener('resize', detectSize)
    detectSize()
    return () => window.removeEventListener('resize', detectSize)
  }, [detectSize])

  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen overflow-hidden">
              <h1 className="text">
          ¡Muchas gracias a todos!
        </h1>
      <div className="full-screen">
        <img className="full-screen"
          src={picture}
          alt="Gracias a todos"        
          height="100wh"
        />
      

        <Confetti 
          width={windowDimension.width}
          height={windowDimension.height}
          recycle={true}
          numberOfPieces={100}
          gravity={0.1}
          wind={0.05}
          initialVelocityY={3}
          confettiSource={{x: 0, y: 0, w: windowDimension.width, h: 0}}
        />
      </div>
    </div>
  )
}