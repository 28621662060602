import './App.css';
import ConfettiComponent from './components/Confetti';

function App() {
  return (
    <div className="App">
      <ConfettiComponent />
    </div>
  );
}

export default App;